<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-500px">
            <div>
                <p class="title">재택근무자 관리 상세</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <table class="tbl-light-view">
                    <colgroup>
                        <col width="100">
                        <col width="*">
                        <col width="100">
                        <col width="*">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>본부</th>
                            <td>{{ pop.info.office_name }}</td>
                            <th>팀</th>
                            <td>{{ pop.info.team_name }}</td>
                        </tr>
                        <tr>
                            <th>이름</th>
                            <td>{{ pop.info.ename }} ({{ pop.info.kname }})</td>
                            <th>재택일수</th>
                            <td>{{ pop.info.cnt_home }}</td>
                        </tr>
                        <tr>
                            <th>시작일</th>
                            <td>{{ pop.info.sdate }}</td>
                            <th>종료일</th>
                            <td>{{ pop.info.edate }}</td>
                        </tr>
                        <tr>
                            <th>사유</th>
                            <td colspan="3" class="preline">{{ pop.info.memo }}</td>
                        </tr>
                        <tr>
                            <th>주요업무</th>
                            <td colspan="3" class="preline">{{ pop.info.task }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import axios from '@/plugins/axios.js'

export default {
    name: 'TelecommutingViewPopup',
    props: {
        idx: {
            type: [Number, String],
            default: 0,
        }
    },
    setup(props, {emit}) {
        const pop = reactive({
            idx : 0,
            info : {},

            doSearch : () => {
                let params = {
                    idx : pop.idx
                };

                axios.get('/rest/mypims/getTelecommutingInfo', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.info = res.data;
                    } else {
                        console.log(res.data);
                    }
                });
            },

            onClose: () => {
                emit('close');
            }
        });
        
        
        onMounted(() => {
            // Mounted
            pop.idx    = props.idx?props.idx:"0";

            pop.doSearch();
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>