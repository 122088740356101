<template>
    <div class="con-wrap">
        <TelecommutingViewPopup :idx="pop.idx" v-if="pop.isShow" @close="pop.isShow=false"></TelecommutingViewPopup>
        <CarrotTitle title="재택근무자 관리"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <div>
                        <table class="table-row table-serach">
                            <tbody>
                                <tr>
                                    <th width="180">기간</th>
                                    <td>
                                        <carrot-date-picker v-model="bbs.sdate" class="w-110px dp-inblock"></carrot-date-picker>
                                        ~
                                        <carrot-date-picker v-model="bbs.edate" class="w-110px dp-inblock"></carrot-date-picker>
                                        <!-- <input type="text" class="w-80px"> ~
                                        <input type="text" class="w-80px mr-20"> -->
                                    </td>
                                    <th width="180">이름</th>
                                    <td>
                                        <input type="text" class="w-400px" v-model="bbs.title">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button class="btn-default float-right mt-10" @click="bbs.doInit">검색</button>
                        <div class="clear"></div>
                    </div>

                    <div class="mt-50 mb-20">
                        <CarrotDept v-model="bbs.office" class="w-200px mr-10"></CarrotDept>
                        <CarrotTeam v-model:idx_office.sync="bbs.office" v-model="bbs.team" @change="bbs.doInit()" class="w-200px"></CarrotTeam>
                        <button @click="bbs.downExcel" class="btn-default float-right h-30">엑셀 다운로드</button>
                        <div class="clear"></div>
                    </div>
                    
                    <table class="table-col">
                        <colgroup>
                            <col width="80">
                            <col width="240">
                            <col width="200">
                            <col width="170">
                            <col width="140">
                            <col width="140">
                            <col width="110">
                            <col width="100">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>본부</th>
                                <th>팀</th>
                                <th>이름</th>
                                <th>재택시작</th>
                                <th>재택종료</th>
                                <th>재택일수</th>
                                <th>상세</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in bbs.list" :key="i">
                                <td>{{ irow.num }}</td>
                                <td>{{ irow.office_name }}</td>
                                <td class="txt-ellipsis">{{ irow.team_name }}</td>
                                <td>{{ irow.ename }} ({{ irow.kname }})</td>
                                <td>{{ irow.sdate }}</td>
                                <td>{{ irow.ename }}</td>
                                <td>{{ irow.cnt_home }}</td>
                                <td><button class="btn-default dp-inblock" @click="pop.showView(irow.idx)">보기</button></td>
                            </tr>
                            <tr v-if="bbs.total==0">
                                <td colspan="8">재택근무자 정보가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging :total="bbs.total" :list_per_page="bbs.rows" v-model="bbs.page" @change="bbs.doSearch"></CarrotPaging>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js';
import Swal from 'sweetalert2';
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import TelecommutingViewPopup from '@/components/popup/myPIMS/TelecommutingViewPopup.vue'
import { useStore } from 'vuex';


export default {
    layout:"myPIMS",
    components: {
        CarrotDatePicker,
        TelecommutingViewPopup
    },
    setup() {
        const router = new useRouter();
        const toast  = useToast();
        const store  = useStore();

        const pop = reactive({
            // Popup 노출 여부
            idx : 0,
            isShow: false,

            showView : (idx) => {
                pop.idx = idx;
                pop.isShow = true;
            },

            hideView : () => {
                pop.isShow = false;
                pop.idx = 0;
            }
        });

        const bbs = reactive({
            isAuth : "N",

            page : 1,
            rows : 20,
            sdate  : "",
            edate  : "",
            title  : "",
            office : 0,
            team   : 0,

            list  : [], total : 0,

            downExcel : () => {
                // let params = {
                //     sdate  : bbs.sdate,
                //     edate  : bbs.edate,
                //     title  : bbs.title,
                //     office : bbs.office,
                //     team   : bbs.team
                // };
                
                location.href = "https://intranet.mxm.kr/excel/telecommuting_list?sdate=" + bbs.sdate + "&edate=" + bbs.edate + "&title=" + bbs.title + "&office=" + bbs.office + "&team=" + bbs.team;
            },

            doInit : () => {
                bbs.page = 1;
                bbs.doSearch();
            },

            doSearch : () => {
                let params = {
                    page : bbs.page,
                    rows : bbs.rows,
                    sdate  : bbs.sdate,
                    edate  : bbs.edate,
                    title  : bbs.title,
                    office : bbs.office,
                    team   : bbs.team
                };

                axios.get("/rest/mypims/getTelecommutingList", { params: params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;
                        bbs.isAuth   = res.data.is_auth;

                        // 현재는 팀 구성이 되어있지 않으므로 스탭을 지정하여 체크하도록 변경, 3995:슈리, 19054:헤이즐
                        if( bbs.isAuth == "N" && store.state.pd_auth == false ) {
                        // if( bbs.isAuth == "N" ){
                            Swal.fire({
                                title : '재택근무자 관리',
                                text  : '권한이 없습니다.'
                            }).then(() => {
                                router.go(-1);
                            });
                        }
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        onMounted(() => {
            if( store.state.pd_auth != true && store.state.position != '리드' ) {
                router.go(-1);
                Swal.fire({
                    title : '재택근무 관리',
                    text  : '접근 권한이 없습니다.'
                });
                return;
            }

            let ss = sessionStorage.getItem('ssTelecommutingList');
            console.log(ss);
        });
        
        return {pop, bbs};
    }
}
</script>

<style lang="scss" scoped>
</style>